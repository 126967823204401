import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { PortalAPIUrl } from '../../constants/environmentVariables';
import { getReactPlugin } from '../../utils/appInsights';
import LogScope from '../../constants/logConstants';
import { LoginAPIHeaders } from '../../constants/apiConstants';

export const get = async (resource) => {
  const payload = {
    data: {},
    error: undefined,
  };

  const appInsights = getReactPlugin();

  const url = `${PortalAPIUrl}/api/${resource}`;
  appInsights.trackTrace({
    message: `${LogScope}: loginClient GET started: Request to ${url}`,
    severityLevel: SeverityLevel.Information,
  }, {
    requestHeader: LoginAPIHeaders,
  });

  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: LoginAPIHeaders,
    });

    const data = await response.json();
    /* istanbul ignore next */
    if (data && (data.error || response.ok === false)) {
      /* istanbul ignore next */
      appInsights.trackTrace({
        message: `${LogScope}: Request to ${url} failed with status code: ${response.status}`,
        severityLevel: SeverityLevel.Warning,
      }, {
        requestHeader: LoginAPIHeaders,
        response: JSON.stringify(data),
      });
      /* istanbul ignore next */
      payload.error = data.error;
    } else {
      payload.data = data;
    }
  } catch (error) {
    /* istanbul ignore next */
    payload.error = error;
    /* istanbul ignore next */
    appInsights.trackTrace({ message: `${LogScope}: ${error.message}`, severityLevel: SeverityLevel.Critical }, { errorDetails: error });
  } finally {
    appInsights.trackTrace({ message: `${LogScope}: loginClient GET completed: Request to ${url}`, severityLevel: SeverityLevel.Information });
  }
  return payload;
};

export const post = async (resource, bodyData) => {
  const payload = {
    data: undefined,
    error: undefined,
  };

  const appInsights = getReactPlugin();

  const url = `${PortalAPIUrl}/api/${resource}`;
  const body = JSON.stringify(bodyData);
  appInsights.trackTrace({
    message: `${LogScope}: loginClient POST started: Request to ${url}`,
    severityLevel: SeverityLevel.Information,
  }, {
    requestBody: body,
    requestHeader: LoginAPIHeaders,
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: LoginAPIHeaders,
      body,
    });

    const data = await response.json();
    /* istanbul ignore next */
    if (data && (data.error || response.ok === false)) {
      /* istanbul ignore next */
      appInsights.trackTrace({
        message: `${LogScope}: Request to ${url} failed with status code: ${response.status}`,
        severityLevel: SeverityLevel.Warning,
      }, {
        requestBody: body,
        requestHeader: LoginAPIHeaders,
        response: JSON.stringify(data),
      });
      /* istanbul ignore next */
      payload.error = data.error;
    } else {
      payload.data = data;
    }
  } catch (error) {
    appInsights.trackTrace({ message: `${LogScope}: ${error.message}`, severityLevel: SeverityLevel.Critical }, { errorDetails: error });
  } finally {
    appInsights.trackTrace({ message: `${LogScope}: loginClient POST completed: Request to ${url}`, severityLevel: SeverityLevel.Information });
  }

  return payload;
};
