import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import LoadingCircle, { CircleSizes } from '@medefer/loading-circle';
import { isUserKeyValid } from '../services/authorization-service';
import { AppRoutes } from '../constants/routing';
import './LandingView.css';

const KeyValidationSteps = {
  Default: 0,
  Validating: 1,
  Valid: 2,
  Invalid: 3,
};

function LandingView() {
  const [validationStep, setValidationStep] = useState(KeyValidationSteps.Default);
  const { key } = useParams();

  const validateKey = async () => {
    const valid = await isUserKeyValid(key);
    if (valid) setValidationStep(KeyValidationSteps.Valid);
    else setValidationStep(KeyValidationSteps.Invalid);
  };

  useEffect(
    () => {
      setValidationStep(validationStep.Validating);
      validateKey();
    },
    [key],
  );

  if (validationStep === KeyValidationSteps.Valid) {
    return <Navigate to={AppRoutes.LoginBooking} replace />;
  }

  if (validationStep === KeyValidationSteps.Invalid) {
    return <Navigate to={AppRoutes.SomethingHasChanged} replace />;
  }

  return (
    <div className="landing-view">
      <LoadingCircle size={CircleSizes.Large} />
    </div>
  );
}

export default LandingView;
