import moment from 'moment';

const getFormattedDayOrMonth = (val) => {
  if (val && val.toString().length === 1 && val > 0 && val < 10) return `0${val}`;

  return `${val}`;
};

const isDateValid = (day, month, year) => {
  try {
    const formattedDate = `${getFormattedDayOrMonth(day)}/${getFormattedDayOrMonth(month)}/${year}`;
    return moment(formattedDate, 'DD/MM/YYYY', true).isValid();
  } catch (error) {
    /* istanbul ignore next */
    return false;
  }
};

export default isDateValid;
