import React from 'react';
import LinkExpired from '../components/booking-login/LinkExpired';
import withNavbar from '../components/hoc/WithNavBar';
import { clearSession } from '../services/authorization-service';

function UnauthorizedView() {
  React.useEffect(() => {
    clearSession();
  }, []);
  return (
    <LinkExpired />
  );
}

export default withNavbar(UnauthorizedView);
