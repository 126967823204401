import React, { useState } from 'react';
import Alert, { AlertTypes } from '@medefer/alert';
import LinkButton from '@medefer/link-button';
import Button from '@medefer/button';
import DateInput from '@medefer/date-input';
import InputField from '@medefer/input-field';
import Section from '@medefer/section';
import { useNavigate } from 'react-router-dom';
import isDateValid from '../../utils/validationUtil';
import { authenticateUser } from '../../services/authorization-service';
import { BookingMFERoutePrefix, PCTPhone, PCTEmail } from '../../constants/environmentVariables';
import toggleChatWidget from '../../services/chat-bot-widget-service';
import './BookingLoginForm.css';

const initialState = {
  error: '',
  isLoginWithNHSNumber: true,
  nhsNumber: '',
  fullName: '',
  postCode: '',
  date: {
    day: '',
    month: '',
    year: '',
  },
};

function BookingLoginForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);

  const [submitting, setSubmitting] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleOnFormChange = (newData) => {
    setFormData(newData);
  };

  const setError = (error) => {
    setFormData({ ...formData, error });
  };

  const requiredInputsValid = () => {
    const isDateOfBirthValid = !(!formData.date.day || !formData.date.month || !formData.date.year);

    if (formData.isLoginWithNHSNumber) { return !!formData.nhsNumber && isDateOfBirthValid; }

    return !!formData.fullName && !!formData.postCode && isDateOfBirthValid;
  };

  const validateForm = () => {
    setError('');

    if (formData.isLoginWithNHSNumber) {
      if (!formData.nhsNumber) {
        setError('Please enter NHS number.');

        return false;
      }
    } else {
      if (!formData.fullName) {
        setError('Please enter full name.');

        return false;
      }

      if (!formData.postCode) {
        setError('Please enter postcode.');

        return false;
      }
    }
    if (!formData.date.day && !formData.date.month && !formData.date.year) {
      setError('Please enter date of birth.');

      return false;
    }
    if (!isDateValid(formData.date.day, formData.date.month, formData.date.year)) {
      setError('The date of birth is incorrect!');

      return false;
    }

    return true;
  };

  const handleOnSubmit = async (event) => {
    setSubmitting(true);
    if (validateForm()) {
      const { day, month, year } = formData.date;
      const result = await authenticateUser(
        formData.isLoginWithNHSNumber,
        `${day}/${month}/${year}`,
        formData.postCode,
        formData.nhsNumber,
        formData.fullName,
      );

      if (result.IsSuccess) {
        navigate(`/${BookingMFERoutePrefix}`, { replace: true });
      } else if (result.Count > 0) {
        setError(`Incorrect verification details, you have ${result.Count} attempts left, please try again.`);
      } else {
        setFormDisabled(true);
        setError((
          <>
            You have exceeded the number of allowed login attempts.
            <div>
              Please
              {' '}
              <LinkButton onClick={toggleChatWidget} label="chat with us here" className="chatWithUsLink" />
              {' '}
              or contact us on
              {' '}
              {PCTPhone}
              {' '}
              or
              {' '}
              {PCTEmail}
              ,
              we can assist you with your booking.
            </div>
          </>));
      }
    }
    setSubmitting(false);
    event.preventDefault();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Section
        className="mb-5"
        title="Verify your identity"
      >
        <span>
          Once you have verified your identity, you will be taken to the appointment booking screen.
        </span>
      </Section>
      {(formData.error
        && (
          <Alert
            type={AlertTypes.Warning}
            className="mb-5"
          >
            {formData.error}
          </Alert>
        ))}
      {formData.isLoginWithNHSNumber
        && (
          <InputField
            id="nhsNumberInput"
            className="mb-5"
            label="Please enter your NHS number"
            value={formData.nhsNumber}
            onChange={(e) => handleOnFormChange({ ...formData, nhsNumber: e.target.value })}
            disabled={formDisabled}
          />
        )}
      {!formData.isLoginWithNHSNumber
        && (
          <>
            <InputField
              id="fullNameInput"
              className="mb-5"
              label="Please provide your full name (including middle names) as registered with your GP"
              value={formData.fullName}
              onChange={(e) => handleOnFormChange({ ...formData, fullName: e.target.value })}
              disabled={formDisabled}
            />
            <InputField
              id="postCodeInput"
              className="mb-5"
              label="What is your postcode?"
              value={formData.postCode}
              onChange={(e) => handleOnFormChange({ ...formData, postCode: e.target.value })}
              disabled={formDisabled}
            />
          </>
        )}
      <DateInput
        id="dateOfBirthInput"
        label="What is your date of birth?"
        className="mb-5"
        value={formData.date}
        onChange={(dateValue) => handleOnFormChange({ ...formData, date: { ...dateValue } })}
        disabled={formDisabled}
      />
      <Button
        className="mb-5"
        label="Continue"
        onClick={handleOnSubmit}
        loading={submitting}
        disabled={submitting || formDisabled || !requiredInputsValid()}
      />
      {!formDisabled && (
        <LinkButton
          className="mb-5"
          label={formData.isLoginWithNHSNumber ? "What if I don't have my NHS number?" : 'Continue with NHS number'}
          onClick={() => handleOnFormChange({
            ...formData,
            isLoginWithNHSNumber: !formData.isLoginWithNHSNumber,
            error: '',
          })}
        />
      )}
    </form>
  );
}

export default BookingLoginForm;
