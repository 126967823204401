import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import withNavbar from '../components/hoc/WithNavBar';
import MicroFrontend from '../components/MicroFrontend';
import { BookingMFEName, BookingMFEUrl } from '../constants/environmentVariables';

function BookingMFE() {
  const history = useNavigate();
  return <MicroFrontend history={history} host={BookingMFEUrl} name={BookingMFEName} />;
}

BookingMFE.defaultProps = {};

export default withNavbar(BookingMFE);
