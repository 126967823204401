/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import BookingLoginForm from '../../components/booking-login/BookingLoginForm';
import BookingLoginDescriptions from '../../components/booking-login/BookingLoginDescriptions';
import './BookingLoginView.css';
import withNavbar from '../../components/hoc/WithNavBar';

function BookingLoginView() {
  return (
    <div className="booking-login-container">
      <div className="booking-login-container-item booking-login-inputs">
        <BookingLoginForm />
      </div>
      <div className="booking-login-container-item booking-login-descriptions">
        <BookingLoginDescriptions />
      </div>
    </div>
  );
}

export default withNavbar(BookingLoginView);
