import React from 'react';
import MedeferLogo, { MedeferLogoTypes } from '@medefer/medefer-logo';
import PageLayout from '@medefer/page-layout';
import NHSLogo from '../NHSLogo';
import './Header.css';

function Header() {
  return (
    <div className="header-container">
      <PageLayout className="header-layout">
        <div className="nhs-logo-container">
          <NHSLogo />
        </div>
        <div className="header-logo">
          <span>A service provided by</span>
          <MedeferLogo type={MedeferLogoTypes.White} />
        </div>
      </PageLayout>
    </div>
  );
}

export default Header;
