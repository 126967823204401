import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppRoutes } from '../../constants/routing';
import { getUserKey, isUserAuthenticated } from '../../services/authorization-service';

function PatientTokenRoute({ children }) {
  const patientToken = getUserKey();

  if (!patientToken) {
    return <Navigate to={AppRoutes.SomethingHasChanged} replace />;
  }

  if (isUserAuthenticated()) { return <Navigate to={AppRoutes.Landing} replace />; }

  // authorized so return child components
  return children;
}

PatientTokenRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PatientTokenRoute;
