import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import PageLayout from '@medefer/page-layout';
import Header from '../form-controls/Header';
import Footer from '../form-controls/Footer';
import './WithNavBar.css';
import { getReactPlugin } from '../../utils/appInsights';

const withNavbar = (WrappedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class HOC extends React.Component {
    render() {
      return (
        <>
          <Header />
          <div>
            <PageLayout>
              <WrappedComponent />
            </PageLayout>
          </div>
          <div className="footer-container" style={{ marginTop: 'auto' }}>
            <Footer />
          </div>
        </>
      );
    }
  }
  return withAITracking(getReactPlugin(), HOC, (WrappedComponent ? WrappedComponent.name : undefined), 'hocAI');
};

export default withNavbar;
