import React from 'react';
import Section, { SectionTypes } from '@medefer/section';
import Title from '@medefer/title';

function BookingLoginDescriptions() {
  return (
    <>
      <Section
        type={SectionTypes.Info}
        className="mb-5"
      >
        <>
          <div className="mb-1">
            <Title value="What is my appointment for?" />
          </div>
          <span>
            We require more information regarding your referral.
            We would like you to speak with one of our doctors to gather
            more information about your medical issues, to help inform decisions about your care.
          </span>
        </>
      </Section>
      <Section
        type={SectionTypes.Info}
        className="mb-5"
      >
        <>
          <div className="mb-1">
            <Title value="Why do you ask for my NHS number and date of birth?" />
          </div>
          <span>
            Medefer asks for your NHS number and date of birth to allow us to
            connect to your medical record and protect your personal information.
          </span>
        </>
      </Section>
    </>
  );
}

export default BookingLoginDescriptions;
