import React from 'react';
import './NHSLogo.css';
import NHSBlueLogo from '../images/nhs-blue-logo.jpg';

function NHSLogo() {
  return (
    <img
      className="nhs-logo"
      src={NHSBlueLogo}
      alt="NHS Logo"
    />
  );
}

export default NHSLogo;
