import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppRoutes } from '../../constants/routing';
import { getUserKey, isUserAuthenticated } from '../../services/authorization-service';

function AuthorizedRoute({ children }) {
  const isSuccess = isUserAuthenticated();

  if (!isSuccess) {
    if (getUserKey()) { return <Navigate to={AppRoutes.LoginBooking} replace />; }

    return <Navigate to={AppRoutes.SomethingHasChanged} replace />;
  }

  // authorized so return child components
  return children;
}

AuthorizedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthorizedRoute;
