import { LoginAPIResources } from '../constants/apiConstants';
import * as loginClient from './clients/loginClient';

export const validateKey = async (key) => {
  const payload = await loginClient.get(LoginAPIResources.ValidateKey(key));
  return {
    isKeyValid: payload.data.IsKeyValid,
    error: payload.error,
  };
};

export const login = async (body) => {
  const payload = await loginClient.post(LoginAPIResources.Login, body);

  return payload;
};
