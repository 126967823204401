import * as LoginAPI from '../api/loginAPI';

const SessionKeys = {
  UserInfo: 'User',
  UserKey: 'UserKey',
};

const setUserKey = (key) => {
  localStorage.setItem(SessionKeys.UserKey, key);
};

export const clearSession = () => {
  localStorage.clear();
};

export const getUserKey = () => localStorage.getItem(SessionKeys.UserKey);

const setUser = (user) => {
  localStorage.setItem(SessionKeys.UserInfo, user);
};

export const isUserKeyValid = async (key) => {
  localStorage.removeItem(SessionKeys.UserInfo);
  const result = await LoginAPI.validateKey(key);
  if (!result.isKeyValid) {
    localStorage.removeItem(SessionKeys.UserKey);
    return false;
  }

  setUserKey(key);
  return true;
};

export const authenticateUser = async (
  isLoginWithNHSNumber,
  dob,
  postCode,
  nhsNumber,
  fullName,
) => {
  const userKey = getUserKey();
  localStorage.removeItem(SessionKeys.UserInfo);
  if (userKey) {
    let body = {
      token: userKey,
      dob,
      postCode,
      fullName,
    };

    if (isLoginWithNHSNumber) {
      body = {
        token: userKey,
        dob,
        nhsNumber,
      };
    }
    const userInfo = await LoginAPI.login(body);
    if (userInfo.data) {
      setUser(JSON.stringify(userInfo.data));
      return userInfo.data;
    }
  }
  return { IsSuccess: false };
};

export const isUserAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem(SessionKeys.UserInfo));
  if (user && user.IsSuccess) { return true; }

  return false;
};
