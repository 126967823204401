/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { AppRoutes } from './constants/routing';
import LandingView from './views/LandingView';
import UnauthorizedView from './views/UnauthorizedView';
import BookingMFE from './mfes/BookingMFE';
import { BookingMFERoutePrefix } from './constants/environmentVariables';
import BookingLoginView from './views/Login/BookingLoginView';
import PatientTokenRoute from './components/routing/PatientTokenRoute';
import AuthorizedRoute from './components/routing/AuthorizedRoute';
import ChatBotWidget from './components/form-controls/ChatBotWidget';
import loadAppInsights from './utils/appInsights';
import ErrorView from './views/ErrorView';

const reactPlugin = loadAppInsights();

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <ErrorView />} appInsights={reactPlugin}>
        <BrowserRouter>
          <ChatBotWidget />
          <Routes>
            <Route exact path={AppRoutes.Error} element={<UnauthorizedView />} />
            <Route
              exact
              path={AppRoutes.LoginBooking}
              element={<PatientTokenRoute><BookingLoginView /></PatientTokenRoute>}
            />
            <Route path="/" element={<Navigate to={`/${BookingMFERoutePrefix}`} />} />
            <Route path={`/${BookingMFERoutePrefix}`} element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>} />
            <Route
              path={AppRoutes.BookingAvailableSlots}
              element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>}
            />
            <Route
              path={AppRoutes.BookingAppointmentSummary}
              element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>}
            />
            <Route
              path={AppRoutes.BookingAppointmentConfirmation}
              element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>}
            />
            <Route
              path={AppRoutes.DeclineAppointment}
              element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>}
            />
            <Route
              path={AppRoutes.DeclineConfirmation}
              element={<AuthorizedRoute><BookingMFE /></AuthorizedRoute>}
            />
            <Route path={`${AppRoutes.Landing}/:key`} element={<LandingView />} />
            <Route path={AppRoutes.SomethingHasChanged} element={<UnauthorizedView />} />
          </Routes>
        </BrowserRouter>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
