// https://developers.freshchat.com/web-sdk/
/* eslint-disable no-unused-expressions */
function ChatBotWidget() {
  const initFreshChat = () => {
    window.fcWidget.init({
      token: '2717980d-1529-4242-bcc6-8fd4948328b3',
      host: 'https://wchat.eu.freshchat.com',
    });
  };

  const initialize = (i, t) => {
    let e;
    i.getElementById(t) ? initFreshChat() : ((e = i.createElement('script')).id = t, e.async = !0, e.src = 'https://wchat.eu.freshchat.com/js/widget.js', e.onload = initFreshChat, i.head.appendChild(e));
  };

  const initiateCall = () => {
    initialize(document, 'freshchat-js-sdk');
  };

  window.addEventListener ? window.addEventListener('load', initiateCall, !1) : window.attachEvent('load', initiateCall, !1);

  return '';
}

export default ChatBotWidget;
