export const AppRoutes = {
  Landing: '/',
  LoginBooking: '/login',
  Error: '/error',
  SomethingHasChanged: '/something-has-changed',
  BookingAvailableSlots: '/booking/available-slots',
  BookingAppointmentSummary: '/booking/appointment-summary',
  BookingAppointmentConfirmation: '/booking/appointment-confirmation',
  DeclineAppointment: '/booking/decline-appointment',
  DeclineConfirmation: '/booking/decline-confirmation',
};

export default AppRoutes;
