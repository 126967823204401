import React from 'react';
import Section from '@medefer/section';
import './LinkExpired.css';
import LinkButton from '@medefer/link-button';
import toggleChatWidget from '../../services/chat-bot-widget-service';
import { PCTEmail, PCTPhone } from '../../constants/environmentVariables';

function LinkExpired() {
  return (
    <Section
      className="link-expired-container mt-5"
      title="Something has changed"
    >
      <span className="link-expired-content">
        Sorry, something has gone wrong. Please contact the Patient Care Team on:
        <div className="mt-5">
          Email:
          {' '}
          {PCTEmail}
        </div>
        <div className="mt-1">
          Phone:
          {' '}
          {PCTPhone}
        </div>
        <div className="mt-1">
          <LinkButton onClick={toggleChatWidget} label="Chat with us here" />
        </div>
      </span>
    </Section>
  );
}

export default LinkExpired;
