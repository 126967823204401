import React from 'react';
import PropTypes from 'prop-types';
import LoadingCircle, { CircleSizes } from '@medefer/loading-circle';
import './MicroFrontend.css';

/* a few lines have been ignored for test coverage due to edge cases */

class MicroFrontend extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    const { name, host, document } = this.props;
    const scriptId = `mfe-script-${name}`;

    /* istanbul ignore next */
    if (document.getElementById(scriptId)) {
      this.mountMicroFrontend();
      this.setState(
        { mounted: true },
      );
      return;
    }
    this.setState(
      { mounted: false },
    );

    fetch(`${host}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        /* istanbul ignore next */
        if (manifest.files && manifest.files['main.js']) {
          script.src = `${host}${manifest.files['main.js']}`;
        } else { script.src = `${host}${manifest['main.js']}`; }

        script.onload = this.mountMicroFrontend;
        document.head.appendChild(script);

        // css load
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';

        /* istanbul ignore next */
        if (manifest.files && manifest.files['main.css']) {
          link.href = `${host}${manifest.files['main.css']}`;
        } else { link.href = `${host}${manifest['main.css']}`; }
        document.head.appendChild(link);
      });
  }

  componentWillUnmount() {
    const { name, window } = this.props;
    /* istanbul ignore next */
    if (window[`unmount${name}`]) { window[`unmount${name}`](`${name}-container`); }
  }

  /* istanbul ignore next */
  mountMicroFrontend = () => {
    const { name, window, history } = this.props;

    if (window[`mount${name}`]) {
      this.setState(
        { mounted: true },
      );
      window[`mount${name}`](`${name}-container`, history);
    }
  };

  renderLoadingCircle() {
    const { mounted } = this.state;
    if (mounted !== true) {
      return (
        <div className="mfe-loading-content">
          <LoadingCircle size={CircleSizes.Large} />
        </div>
      );
    }

    return '';
  }

  render() {
    const { name } = this.props;
    return (
      <>
        <main id={`${name}-container`} data-testid={`${name}-container`} />
        {this.renderLoadingCircle()}
      </>
    );
  }
}

MicroFrontend.defaultProps = {
  document,
  window,
  history: PropTypes.func,
};

MicroFrontend.propTypes = {
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  document: PropTypes.instanceOf(Document),
  window: PropTypes.instanceOf(window.constructor),
  history: PropTypes.func,
};

export default MicroFrontend;
